import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

interface GlossaryItem {
  term: string;
  definition: string;
}

export interface GlossaryProps {
  title: string;
  intro: string;
  items: GlossaryItem[];
}

export function Glossary({ title, intro, items }: GlossaryProps) {
  items.sort((a: GlossaryItem, b: GlossaryItem) => {
    if (a.term < b.term) {
      return -1;
    }
    if (a.term > b.term) {
      return 1;
    }
    return 0;
  });
  return (
    <VStack>
      <Heading>{title}</Heading>
      <Text>{intro}</Text>
      <Accordion defaultIndex={[]} width="100%">
        {items.map((item: GlossaryItem) => (
          <AccordionItem key={item.term}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {item.term}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{item.definition}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
}
