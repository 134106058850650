import { graphql } from 'gatsby';
import React from 'react';
import { Glossary } from '../components/Glossary';
import { Page } from '../components/Page';
import { seo } from '../components/Seo';

interface GlossaryItem {
  term: string;
  definition: string;
}
interface GlossarySection {
  title: string;
  intro: string;
  items: GlossaryItem[];
}
interface Props {
  data: {
    glossaryJson: {
      main: GlossarySection;
      historical: GlossarySection;
    };
  };
}

export const Head = seo({ title: 'Glossary' });

export default function GlossaryPage({
  data: {
    glossaryJson: { main, historical },
  },
}: Props) {
  return (
    <Page>
      <Glossary {...main} />
      <Glossary {...historical} />
    </Page>
  );
}

export const pageQuery = graphql`
  query glossaryPageData {
    glossaryJson {
      main {
        title
        intro
        items {
          definition
          term
        }
      }
      historical {
        title
        intro
        items {
          definition
          term
        }
      }
    }
  }
`;
